const SLIDE_TIMER = 5000;

const slider = {
    sliderContainer: null,
    dotsContainer: null,
    currentSlide: 0,
    slides: null,
    dots: [],
    timer: null,
    init() {
        // get container
        slider.sliderContainer = document.querySelector('.slider');
        slider.dotsContainer = document.querySelector('.slider .dots');
        // get images urls from .slider>.slider-image
        slider.slides = document.querySelectorAll('.slider > .slider-image');
        // add background-image in style attr
        slider.spawnBackgrounds();
        // spawn the dots
        slider.spawnDots();
        slider.updateDots();
        // start auto slide
        slider.startSlide();
    },
    startSlide() {
        slider.timer = setInterval(slider.nextSlide, SLIDE_TIMER)
    },
    resetTimer() {
        clearInterval(slider.timer);
        slider.startSlide();
    },
    nextSlide() {
        if (slider.currentSlide + 1 === slider.slides.length) {
            slider.gotoSlide(0);
        } else {
            slider.gotoSlide(slider.currentSlide + 1);
        }
    },
    gotoSlide(index) {
        // fade out the current
        slider.slides[slider.currentSlide].classList.remove('show');
        // change the current index
        slider.currentSlide = index;
        // fade in the current
        slider.slides[slider.currentSlide].classList.add('show');
        // update dots
        slider.updateDots();
    },
    spawnBackgrounds() {
        for (const slide of slider.slides) {
            const imageUrl = slide.dataset.imageUrl;
            slide.setAttribute('style', `background-image:url(${imageUrl})`);
        }
    },
    spawnDots() {
       slider.slides.forEach((slide, slideIndex) => {
            const newDot = document.createElement('div');
            newDot.classList.add('dot');
            newDot.dataset.slideId = slideIndex;
            slider.dots.push(newDot);
            slider.dotsContainer.appendChild(newDot);

            newDot.addEventListener('click', slider.handleDotClick);
        });
    },
    updateDots() {
        for (const dot of slider.dots) {
            dot.classList.remove('focus');
        }
        slider.dots[slider.currentSlide].classList.add('focus');
    },
    handleDotClick(event) {
        const targetId = Number(event.currentTarget.dataset.slideId);
        slider.gotoSlide(targetId);
        slider.resetTimer();
    }
}

document.addEventListener('DOMContentLoaded', slider.init);