const mainMenuModule = {
    menuBtn: null,
    closeBtn: null,
    menu: null,
    init: function() {
        mainMenuModule.menuBtn = document.querySelector('.menu-btn');
        mainMenuModule.closeBtn = document.querySelector('.close-btn');
        mainMenuModule.menu = document.querySelector('.mobile-nav ul');

        mainMenuModule.menuBtn.addEventListener('click', mainMenuModule.toggleMenu);
        mainMenuModule.closeBtn.addEventListener('click', mainMenuModule.toggleMenu);
    },
    toggleMenu: function() {
        mainMenuModule.menuBtn.classList.toggle('hidden');
        mainMenuModule.closeBtn.classList.toggle('hidden');
        mainMenuModule.menu.classList.toggle('hidden');
    }
}

document.addEventListener('DOMContentLoaded', mainMenuModule.init);